<template>
  <div>
    <div
      class="container"
      v-for="(p, index) in infoList"
      :key="index"
      :class="{ dark: p.bg == '#F4F9FF' }"
    >
      <div class="content">
        <div class="head">{{ p.iconTitle }}</div>
        <div class="title">{{ p.title }}</div>
        <div class="des" v-for="(item, id) in p.desList" :key="id">
          {{ item }}
        </div>
        <div class="img"><img :src="p.iconSrc" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Information",
  data() {
    return {
      //srca: require("@/assets/img/pic_01.png"),
    };
  },
  props: {
    infoList: Array,
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";

.container {
  //width: 100%;
  //height: rem(916px);
  background: #ffffff;
  padding: rem(80px 27px 0);
  text-align: center;
  .content {
      display: flex;
      flex-direction: column;
      align-items: center;
    .head {
      font-size: rem(40px);
      font-family: HelveticaNeue-Bold, HelveticaNeue;
      font-weight: bold;
      color: #2f9cf6;
      line-height: rem(66px);
    }
    .title {
      font-size: rem(28px);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #191919;
      line-height: rem(40px);
      margin: rem(8px 0 40px);
    }
    .des {
      font-size: rem(24px);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #757575;
      line-height: rem(33px);
      padding: rem(0 10px);
    }
  }
  .img {
      width: rem(510px);
    img {
      display: block;
      margin: 0 auto;
      width: rem(510px);
      //height: rem(540px);
    }
  }
}
.dark {
  background: #f4f9ff;
}
// .container:nth-child(1){
//    img {
//        width: rem(612px);
//    }
// }
.container:nth-child(4){
    height: rem(986px);
}
</style>
